export const ItaContent = {
  title: 'Nota Legale',
  content: [
    'Nome della ditta: iMile Italy S.r.l.',
    'Indirizzo Sede Legale: Via Giosuè Carducci, 15 20123 Milano',
    'Indirizzo PEC: imileitaly@registerpec.it',
    'Codice fiscale - Partita IVA e numero di iscrizione: 13027090961',
    'del Registro delle Imprese di MILANO MONZA BRIANZA LODI',
    'Numero REA: MI-2699003',
  ],
}

export const AusContent = {
  title: 'Legal Notice',
  content: [
    'Company Name: IMILE DELIVERY &LOGISTIC PTY LTD',
    'ACN: 664403785',
    'Email Address: customerservice-aus@imile.me',
    "Locality of Registered Office: WAREHOUSE 11B' OFFICE 11B 9-11 FERNDELL STREET SOUTH GRANVILLE NSW, SOUTH GRANVILLE, NSW 2142",
  ],
}

export const enContent = {
  title: 'Legal Notice',
  content: [
    'Company Name: iMile Italy S.r.l.',
    'Registered office address: Via Giosuè Carducci, 15 20123 Milano',
    'PEC Address: imileitaly@registerpec.it',
    'Tax code - VAT number and registration number: 13027090961',
    'of the Register of Companies of MILAN MONZA BRIANZA LODI',
    'Number REA: MI-2699003',
  ],
}

export const plContent = {
  title: 'Informacja prawna',
  content: [
    'Nazwa: Imile logistics and transportation spółka z ograniczoną odpowiedzialnością',
    'Siedziba: Bielany Wrocławskie',
    'Adres: ul. Magazynowa 8, DC5, 55-040 Bielany Wrocławskie',
    'Wpisana do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy ',
    'dla M.St. Warszawy w Warszawie, XIV Wydział Gospodarczy, pod nr KRS 0001008750 ',
    'NIP: 5252935916',
    'REGON:523946206',
    'Kapitał zakładowy w wysokości 5000 zł',
  ],
}
